export default [
  {
    header: 'Работа',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Действия',
    route: 'actions-home',
    icon: 'SlidersIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Все дела',
    route: 'cases-home',
    icon: 'ClipboardIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Архив',
    route: 'archive-home',
    icon: 'ArchiveIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Рабочее место',
    route: 'workplace-home',
    icon: 'BriefcaseIcon',
    permissions: ['employee', 'rester_employee'],
  },
  {
    title: 'Портфели',
    route: 'portfolios',
    icon: 'BatteryIcon',
    permissions: ['employee', 'rester_employee', 'dk_manager'],
  },
  {
    title: 'Добрый коллектор',
    route: 'collector',
    icon: 'PhoneCallIcon',
    permissions: ['employee', 'rester_employee', 'dk_operator', 'dk_manager'],
  },
  {
    header: 'Администрирование',
    permissions: ['rester_employee', 'dk_manager'],
  },
  {
    title: 'Пользователи',
    route: 'users-home',
    icon: 'UsersIcon',
    permissions: ['dk_manager'],
  },
  {
    title: 'Роли',
    route: 'roles-home',
    icon: 'UserPlusIcon',
  },
  {
    title: 'Импорт реестров',
    route: 'import-registries',
    icon: 'DownloadIcon',
    permissions: ['admin', 'rester_employee', 'dk_manager'],
  },
  {
    title: 'Справочник',
    route: 'reference',
    icon: 'SettingsIcon',
  },
  {
    header: 'Конфигурация',
    permissions: ['dk_manager'],
  },
  {
    title: 'Настройка шаблона',
    route: 'actions-settings',
    icon: 'CodepenIcon',
    permissions: ['dk_manager'],
  },
  {
    title: 'Настройка действий',
    route: 'process-settings',
    icon: 'CodepenIcon',
    permissions: ['dk_manager'],
  },

  {
    header: 'Статистика ',
  },
  {
    title: 'Статистика',
    route: 'statistics-home',
    icon: 'TrendingUpIcon',
  },
  // {
  //   header: "Pages",
  //   icon: "FileIcon",
  //   children: [
  //     // {
  //     //   title: 'Home',
  //     //   route: 'home',
  //     //   icon: 'HomeIcon',
  //     // },
  //     // {
  //     //   title: 'Second Page',
  //     //   route: 'second-page',
  //     //   icon: 'FileIcon',
  //     // },
  //   ],
  // },
]
